/**
 * Colors
 */
/**
 * Fonts
 */
/**
 * Base
 */
:root {
  color-scheme: dark;
}
html,
body {
  background-color: #121212;
  color: #aaa;
  font-family: 'Source Serif Pro', serif;
  font-size: 100%;
  font-weight: 400;
  line-height: 1.5;
  margin: 0;
  padding: 0;
}
/**
 * App
 */
.app {
  min-height: 100%;
  width: 100%;
}
.app__shortcuts {
  bottom: 0;
  padding: 1em;
  position: fixed;
  right: 1.5em;
  text-align: right;
  z-index: 100;
}
/**
 * Editor
 */
.editor {
  background-color: inherit;
  overflow: auto;
}
.editor__content {
  margin: 0 auto;
  max-width: 42em;
  padding: 3.6em 1.8em;
  width: 100%;
}
.editor .ql-container {
  font-size: 1.2em;
  line-height: 1.5;
}
.editor .ql-container p {
  margin: 0 0 0.9em;
}
.editor .ql-container a {
  color: inherit;
}
.editor .ql-container {
  font-family: inherit;
}
/**
 * Presentation
 */
.presentation {
  background-color: #121212;
  display: none;
  overflow: auto;
  user-select: none;
}
.presentation.-is-active {
  display: block;
}
.presentation__slide {
  font-size: 2vw;
  font-weight: 300;
  margin: auto;
  max-width: 60vw;
  padding: 45vh 0;
}
.presentation__slide h1 {
  font-size: 1.5em;
  font-weight: 400;
  margin: 0 0 0.6em;
}
.presentation__slide h2 {
  font-size: 1.2em;
  font-weight: 400;
  margin: 0 0 0.6em;
}
.presentation__slide p {
  margin: 0 0 1.2em;
}
.presentation__slide p ~ ul,
.presentation__slide p ~ ol {
  margin-top: -0.6em;
}
.presentation__slide ul,
.presentation__slide ol {
  margin: 0 0 1.2em;
}
.presentation__slide blockquote {
  border-left: 2px solid;
  font-weight: 400;
  margin: 0 0 1.2em;
  padding: 0 1em;
}
.presentation__slide img {
  max-height: 75vh;
  max-width: 80%;
}
.presentation__slide b,
.presentation__slide strong {
  font-weight: 400;
}
.presentation__slide a {
  color: inherit;
}
/**
 * Presentation button
 */
.presentationButton {
  appearance: none;
  background: none;
  background-image: url("7ca32e1f608f19dbffa70d94f29250ba.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  border: none;
  cursor: pointer;
  filter: invert(66%);
  height: 0;
  outline: none;
  overflow: hidden;
  padding: 2.1em 0 0 2.1em;
  width: 0;
}
.presentationButton.-open {
  background-image: url("7ca32e1f608f19dbffa70d94f29250ba.svg");
}
.presentationButton.-close {
  background-image: url("01d35c99cbe86eef7af575e289d9d492.svg");
}
@media screen and (min-width: 60em) {
  .presentationButton {
    padding: 3em 0 0 3em;
  }
}
